<template>
  <base-list :items="items" />
</template>

<script>
import {
  genSubheaderItem,
  genDefaultItem,
  genMultilineItem,
  genAddItem,
  hideIfEmpty,
  genServiceNameItem
} from '@/utils/list-generators';
import { computed, inject } from '@vue/composition-api';

import { useStateItemMenu } from './stateItemMenu';
import { useMonitoringItemMenu } from './monitoringItemMenu';
import { useManagementItemMenu } from './managementItemMenu';
import { useGeoItemMenu } from './geoItemMenu';
import { useCopy } from '@/compositions/copy';
import { colors } from '@/compositions/map/utils/data';
import { usePopup } from '@/compositions/popup';
import { useStates } from '@/modules/object-types/compositions/states';
import { useMonitoringItems } from '@/modules/object-types/compositions/monitoring-items';
import { useManagementItems } from '@/modules/object-types/compositions/management-items';
import { useGeoItems } from '@/modules/object-types/compositions/geo-items';
import { getObjectImageUrlOrDefault } from '@/provider/utils';
import { usePropertyItemMenu } from '@/modules/object-types/ui/type-card/general/propertyItemMenu';
import { USER_PROPERTY_GROUP_NAME } from '@/modules/object-types/compositions/constants';
import {genAccessListFragment} from "@/modules/access/compositions/access-list-fragment";

export default {
  name: 'TypeCardGeneral',
  setup() {
    const entity = inject('entity');
    const { copyWithAlert } = useCopy();
    const popup = usePopup();

    const { genMenu: genStateItemMenu } = useStateItemMenu();
    const { genMenu: genMonitoringItemMenu } = useMonitoringItemMenu();
    const { genMenu: genManagementItemMenu } = useManagementItemMenu();
    const { genMenu: genPropertyItemMenu } = usePropertyItemMenu();
    const { genMenu: genGeoItemMenu } = useGeoItemMenu();
    const { list: listStates, load: loadStates } = useStates(entity.value.id);
    const {
      list: listMonitoringItems,
      load: loadMonitoringItems
    } = useMonitoringItems(entity.value.id);
    const {
      list: listManagementItems,
      load: loadManagementItems
    } = useManagementItems(entity.value.id);
    const { list: listGeoItems, load: loadGeoItems } = useGeoItems(
      entity.value.id
    );

    loadStates();
    loadMonitoringItems();
    loadManagementItems();
    loadGeoItems();

    const customProperties = computed(() =>
      entity.value.schemaProperties
        .filter(property => property.groupName === USER_PROPERTY_GROUP_NAME)
        .map(property => {
          return genDefaultItem({
            title: `${property.property}: ${property.type?.name}`,
            actions: genPropertyItemMenu({
              id: property.id,
              typeId: entity.value.id
            })
          });
        })
    );

    const stateItems = computed(() =>
      listStates.value
        .filter(state => !state.stateIsDefault?.value)
        .map(state => {
          return genDefaultItem({
            useAvatarImage: true,
            avatarColor: colors[state.stateColor?.value] || colors.default,
            image: getObjectImageUrlOrDefault(state.stateIcon?.value),
            title: state.infoName.value,
            actions: genStateItemMenu({ id: state.id, typeId: entity.value.id })
          });
        })
    );
    const defaultStateItem = computed(() => {
      const state = listStates.value.find(state => state.stateIsDefault?.value);
      if (!state) {
        return [];
      }
      return [
        genDefaultItem({
          useAvatarImage: true,
          avatarColor: colors[state.stateColor?.value] || colors.default,
          image: getObjectImageUrlOrDefault(state.stateIcon?.value),
          title: state.infoName.value,
          actions: genStateItemMenu(
            { id: state.id, typeId: entity.value.id, parentType: entity.value },
            false
          )
        })
      ];
    });
    const monitoringItems = computed(() =>
      listMonitoringItems.value.map(mItem => {
        return genDefaultItem({
          image: getObjectImageUrlOrDefault(mItem.stateIcon?.value),
          icon: mItem.commonAlertCount.value > 0 ? '$alert' : '',
          title: mItem.infoName.value,
          actions: genMonitoringItemMenu({
            id: mItem.id,
            typeId: entity.value.id
          })
        });
      })
    );
    const managementItems = computed(() =>
      listManagementItems.value.map(mItem => {
        return genDefaultItem({
          title: mItem.infoName.value,
          actions: genManagementItemMenu({
            id: mItem.id,
            typeId: entity.value.id
          })
        });
      })
    );
    const geoItems = computed(() =>
      listGeoItems.value.map(mItem => {
        return genDefaultItem({
          icon: mItem.commonAlertCount.value > 0 ? '$alert' : '',
          title: mItem.infoName.value,
          actions: genGeoItemMenu({
            id: mItem.id,
            typeId: entity.value.id
          })
        });
      })
    );

    const items = computed(() => [
      genSubheaderItem('Service'),
      genDefaultItem(
        {
          icon: '$uuid',
          title: entity.value.id
        },
        {
          click: () => copyWithAlert(entity.value.id)
        }
      ),
      genDefaultItem({
        icon: '$tree',
        title: entity.value.objectsCount || 0
      }),
      genServiceNameItem(entity.value.name, {
        click: () => copyWithAlert(entity.value.name)
      }),
      genSubheaderItem('Default state'),
      ...defaultStateItem.value,
      genSubheaderItem('Optional states'),
      ...stateItems.value,
      genAddItem(
        {},
        {
          click: () => {
            popup.open({
              component: () =>
                import(
                  '@/modules/object-types/ui/type-card/general/StateEdit.vue'
                ),
              props: {
                parentSchemaId: entity.value.id
              }
            });
          }
        }
      ),
      genSubheaderItem('Monitoring items'),
      ...monitoringItems.value,
      genAddItem(
        {},
        {
          click: () => {
            popup.open({
              component: () =>
                import(
                  '@/modules/object-types/ui/type-card/general/MonitoringItemEdit.vue'
                ),
              props: {
                parentSchemaId: entity.value.id
              }
            });
          }
        }
      ),
      genSubheaderItem('Management items'),
      ...managementItems.value,
      genAddItem(
        {},
        {
          click: () => {
            popup.open({
              component: () =>
                import(
                  '@/modules/object-types/ui/type-card/general/ManagementItemEdit.vue'
                ),
              props: {
                parentSchemaId: entity.value.id
              }
            });
          }
        }
      ),
      genSubheaderItem('Geo items'),
      ...geoItems.value,
      genAddItem(
        {},
        {
          click: () => {
            popup.open({
              component: () =>
                import(
                  '@/modules/object-types/ui/type-card/general/GeoItemEdit.vue'
                ),
              props: {
                parentSchemaId: entity.value.id
              }
            });
          }
        }
      ),
      genSubheaderItem('Properties'),
      ...customProperties.value,
      genAddItem(
        {},
        {
          click: () => {
            popup.open({
              component: () =>
                import(
                  '@/modules/object-types/ui/type-card/general/PropertyEdit.vue'
                ),
              props: {
                schemaId: entity.value.id
              }
            });
          }
        }
      ),
      ...genAccessListFragment({
        readerGroup: `Readers: ${entity.value.userGroupByReadergroup.groupName}`,
        userGroup: `Users: ${entity.value.userGroupByUsergroup.groupName}`,
        editorGroup: `Editors: ${entity.value.userGroupByEditorgroup.groupName}`
      }),
      ...hideIfEmpty(entity.value.description, [
        genSubheaderItem('Description'),
        genMultilineItem({
          text: entity.value.description
        })
      ])
    ]);

    return { items };
  }
};
</script>

<style></style>
